.legend {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.legend-text {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
}

.comment-tail {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f0f0f0;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

.contact-us-submitted {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
}
