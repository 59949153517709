.background-hero-full {
  transform-origin: top center right bottom;
  width: 100%;
  height: 47.73vw;
  margin: auto;
  overflow: hidden;
  transform: scale(1);
}

@media (width >= 1900px) {
  .background-hero-full {
    height: 800px;
    max-height: 800px;
  }
}

@media (width >= 1750px) {
  .background-hero-full {
    height: 38.7vw;
  }
}

@media (width >= 1500px) {
  .background-hero-full {
    height: 42.57vw;
  }
}

@media (width <= 640px) {
  .background-hero-full {
    height: 100vw;
  }
}

.hero-container {
  border-top-left-radius: 32px;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
}

.zoom-container {
  border: 8px solid #fff;
  border-top-left-radius: 32px;
}

.zoom-container, .zoom-container-bl {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.zoom-container-bl {
  border: 8px solid #fff;
  border-bottom-right-radius: 32px;
}

.zoom-container-br {
  border: 8px solid #fff;
  border-bottom-left-radius: 32px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.zoom-container img {
  transition: transform .3s;
}

.zoom-container:hover img {
  transform: scale(1.2);
}

.zoom-container-bl img {
  transition: transform .3s;
}

.zoom-container-bl:hover img {
  transform: scale(1.2);
}

.zoom-container-br img {
  transition: transform .3s;
}

.zoom-container-br:hover img {
  transform: scale(1.2);
}

.card-hover:hover {
  color: #fff;
  background-color: #33f;
}

.card-hover:hover img {
  filter: brightness(0) invert();
}

.text-hover:hover {
  color: #fff;
}

.legend {
  margin: 10px;
  display: inline-block;
  position: relative;
}

.legend-text {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 10px;
  display: inline-block;
}

.comment-tail {
  border-top: 10px solid #f0f0f0;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-us-submitted {
  justify-content: center;
  align-items: center;
  height: 6rem;
  display: flex;
}
/*# sourceMappingURL=index.9215bfc0.css.map */
