.background-hero-full {
  width: 100%;
  height: calc(37vw * 1.29);
  transform-origin: top center right bottom;
  transform: scale(1);
  overflow: hidden;
  margin: auto;
}
@media (min-width: 1900px) {
  .background-hero-full {
    max-height: 800px;
    height: 800px
  }
}

@media (min-width: 1750px) {
  .background-hero-full {
    height: calc(30vw * 1.29);
  }
}

@media (min-width: 1500px) {
  .background-hero-full {
    height: calc(33vw * 1.29);
  }
}
@media (max-width: 640px) {
  .background-hero-full {
    height: calc(50vw * 2);
  }
}

.hero-container {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  border-top-left-radius: 32px;
}

.zoom-container {
  position: relative;
  border: 8px solid white;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 32px;
}

.zoom-container-bl {
  position: relative;
  border: 8px solid white;
  width: 100%;
  overflow: hidden;
  border-bottom-right-radius: 32px;
}
.zoom-container-br {
  position: relative;
  border: 8px solid white;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: 32px;
}

.zoom-container img {
  transition: transform 0.3s ease;
}

.zoom-container:hover img {
  transform: scale(1.2);
}

.zoom-container-bl img {
  transition: transform 0.3s ease;
}

.zoom-container-bl:hover img {
  transform: scale(1.2);
}

.zoom-container-br img {
  transition: transform 0.3s ease;
}

.zoom-container-br:hover img {
  transform: scale(1.2);
}


.card-hover:hover {
  background-color: #3333FF;
  color: white;
}
.card-hover:hover img {
  filter: brightness(0) invert(1);
}
.text-hover:hover {
  color:white;

}


